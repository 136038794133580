import { Col, Radio, Row, Space } from "antd";
import React, { useState, useEffect } from "react";
import Display from "./Display";

const DaysPicker = () => {
  const now = new Date();
  const [selectedTime, setSelectedTime] = useState("Hour");
  const [nextDisable, setNextDisable] = useState(false);
  const [startTime, setStartTime] = useState(now);
  const [endTime, setEndTime] = useState(now);

  const updateDisplayedTime = () => {
    switch (selectedTime) {
      case "Hour":
        setStartTime(now);
        setEndTime(new Date(now.getTime() + -1 * 60 * 60 * 1000));
        break;
      case "Today":
        setStartTime(now);
        setEndTime(new Date(now.getTime() + -1 * 24 * 60 * 60 * 1000));
        break;
      case "Week":
        setStartTime(now);
        setEndTime(new Date(now.getTime() + -1 * 7 * 24 * 60 * 60 * 1000));
        break;
      case "Month":
        setStartTime(now);
        setEndTime(new Date(now.getTime() + -1 * 30 * 24 * 60 * 60 * 1000));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    updateDisplayedTime();
    isNextDisabled(startTime);
  }, [selectedTime]);

  const handleButtonClick = (decrement) => {
    const timeIncrement = decrement ? -1 : 1;

    switch (selectedTime) {
      case "Hour":
        setStartTime(
          new Date(startTime.getTime() + timeIncrement * 60 * 60 * 1000)
        );
        setEndTime(
          new Date(endTime.getTime() + timeIncrement * 60 * 60 * 1000)
        );
        isNextDisabled(
          new Date(startTime.getTime() + timeIncrement * 60 * 60 * 1000)
        );
        break;
      case "Today":
        setStartTime(
          new Date(startTime.getTime() + timeIncrement * 24 * 60 * 60 * 1000)
        );
        setEndTime(
          new Date(endTime.getTime() + timeIncrement * 24 * 60 * 60 * 1000)
        );
        isNextDisabled(
          new Date(startTime.getTime() + timeIncrement * 24 * 60 * 60 * 1000)
        );
        break;
      case "Week":
        setStartTime(
          new Date(
            startTime.getTime() + timeIncrement * 7 * 24 * 60 * 60 * 1000
          )
        );
        setEndTime(
          new Date(endTime.getTime() + timeIncrement * 7 * 24 * 60 * 60 * 1000)
        );
        isNextDisabled(
          new Date(
            startTime.getTime() + timeIncrement * 7 * 24 * 60 * 60 * 1000
          )
        );
        break;
      case "Month":
        setStartTime(
          new Date(
            startTime.getTime() + timeIncrement * 30 * 24 * 60 * 60 * 1000
          )
        );
        setEndTime(
          new Date(endTime.getTime() + timeIncrement * 30 * 24 * 60 * 60 * 1000)
        );
        isNextDisabled(
          new Date(
            startTime.getTime() + timeIncrement * 30 * 24 * 60 * 60 * 1000
          )
        );
        break;
      default:
        break;
    }
  };

  const isNextDisabled = (startTime) => {
    switch (selectedTime) {
      case "Hour":
        return setNextDisable(startTime.getHours() >= now.getHours());
      case "Today":
        return setNextDisable(startTime.getDate() >= now.getDate());
      case "Week":
        return setNextDisable(startTime.getDate() >= now.getDate());
      case "Month":
        return setNextDisable(startTime.getMonth() + 1 >= now.getMonth() + 1);
      default:
        return setNextDisable(false);
    }
  };

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <Space wrap>
          {["Hour", "Today", "Week", "Month"].map((item, index) => (
            <div key={index} className="flex-center">
              <Radio.Group
                options={[item]}
                onChange={(e) => setSelectedTime(e.target.value)}
                value={selectedTime}
                optionType="button"
                size="large"
                buttonStyle="solid"
              />
              {selectedTime === item && (
                <Display
                  selectedTime={selectedTime}
                  handleButtonClick={handleButtonClick}
                  nextDisable={nextDisable}
                  startTime={startTime}
                  endTime={endTime}
                />
              )}
            </div>
          ))}
        </Space>
      </Col>
    </Row>
  );
};

export default DaysPicker;
