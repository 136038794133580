import { Col, Divider, Row } from "antd";
import VEHICLE from "../../assets/images/dealer/model.svg";
import DISTANCE from "../../assets/images/icons/distance.svg";
import KM from "../../assets/images/icons/totalkm.svg";

const Overview = ({ showModal }) => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
          <Row className="widget">
            <Col span={12} className="mht150 flex-center">
              <div className="head">
                <img src={VEHICLE} alt="Scooter" width="150" />
              </div>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24} className="flex-center">
                  <div className="normal-head mt2">Total Vehicles</div>
                </Col>
                <Col span={24} className="text-center">
                  <div className="head30 mt2">30</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={9} xl={9}>
          <Row className="widget">
            <Col span={12} className="mht150 flex-center">
              <div className="head">
                <img src={DISTANCE} alt="Scooter" width="150" />
              </div>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={24} className="flex-center">
                  <div className="normal-head mt2">Total Distance</div>
                </Col>
                <Col span={24} className="flex-center">
                  <div className="head30 mt2">3640</div>
                  <div className="head mt2025 ml025">km</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <div className="widget">
            <Row className="flex-center" justify="space-around">
              <Col span={18}>Total Heartbeat Tests</Col>
              <Col span={6}>
                <div className="head26">66</div>
              </Col>
            </Row>
            <Row className="flex-center mt1025" justify="space-around">
              <Col span={18}>Total Dyno Tests</Col>
              <Col span={6}>
                <div className="head26">36</div>
              </Col>
            </Row>
            <Row className="flex-center mt1025" justify="space-around">
              <Col span={18}>Total Test Rides</Col>
              <Col span={6}>
                <div className="head26">40</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="widget mt05">
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={11} xl={11}>
              <Row>
                <Col span={12} className="flex-center">
                  <img src={KM} alt="Scooter" width="155" />
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={24} className="flex-center">
                      <div className="normal-head">Top Running</div>
                    </Col>
                    <Divider className="hr-margin" />
                    <Col span={24} className="text-center">
                      <div className="flex-center mt05">
                        <div className="head38 green">265</div>
                        <div className="mt075 ml025">km</div>
                      </div>
                      <div className="mt1">
                        <span className="toggle-btn" onClick={() => showModal()}>
                          Top 10 Vehicles
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={1} className="flex-center">
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11}>
              <Row>
                <Col span={12} className="flex-center">
                  <img src={KM} alt="Scooter" width="155" />
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={24} className="flex-center">
                      <div className="normal-head">Least Running</div>
                    </Col>
                    <Divider className="hr-margin" />
                    <Col span={24} className="text-center">
                      <div className="flex-center mt05">
                        <div className="head38 red">65</div>
                        <div className="mt075 ml025">km</div>
                      </div>
                      <div className="mt1">
                        <span className="toggle-btn" onClick={() => showModal()}>
                          Top 10 Vehicles
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Overview;
