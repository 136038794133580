export const batteryFields = [
  {
    label: "Voltage",
    value: "voltage",
  },
  {
    label: "Temperature",
    value: "temperature",
  },
];
