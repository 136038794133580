import { Col, Divider, Row } from "antd";
import { Sparklines, SparklinesLine } from "react-sparklines";

const Stack = ({ showModal }) => {
  const batteryData = [
    {
      sparklineData: [
        20, 42, 11, 25, 10, 35, 20, 18, 15, 35, 10, 5, 20, 48, 15, 5, 10, 35,
        20, 8, 15,
      ],
      minValue: 44,
      maxValue: 77,
      minVehicles: 10,
      maxVehicles: 30,
      stackName: "Sensor 1",
    },
    {
      sparklineData: [
        20, 42, 11, 25, 10, 35, 20, 18, 15, 35, 10, 5, 20, 48, 15, 5, 10, 35,
        20, 8, 15,
      ],
      minValue: 44,
      maxValue: 77,
      minVehicles: 10,
      maxVehicles: 30,
      stackName: "Sensor 2",
    },
    {
      sparklineData: [
        20, 42, 11, 25, 10, 35, 20, 18, 15, 35, 10, 5, 20, 48, 15, 5, 10, 35,
        20, 8, 15,
      ],
      minValue: 44,
      maxValue: 77,
      minVehicles: 10,
      maxVehicles: 30,
      stackName: "Sensor 2",
    },
  ];
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {batteryData.map((item, index) => {
          return (
            <div key={index} className="sensor-card mt1">
              <Row gutter={[0, 16]}>
                <Col xs={24} sm={24} md={24} lg={3} xl={3} className="title">
                  {item.stackName}
                </Col>
                <Col xs={24} sm={24} md={24} lg={9} xl={9} className="pd05">
                  <Sparklines
                    data={item.sparklineData}
                    limit={20}
                    width={100}
                    height={20}
                    margin={5}
                  >
                    <SparklinesLine color="#b22071" />
                  </Sparklines>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Row
                    gutter={[16, 16]}
                    justify="space-between"
                    className="pd05"
                  >
                    <Col xs={24} sm={24} md={24} lg={8} xl={7}>
                      <div className="text-center">
                        <div className="mt025 grey f12 ml025">Min</div>
                        <div className="head24">{item.minValue}</div>
                      </div>
                    </Col>
                    <Col xs={0} sm={0} md={1} lg={1} xl={1}>
                      <Divider type="vertical" style={{ height: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={7}>
                      <div className="text-center">
                        <div className="mt025 grey f12 ml025">Max</div>
                        <div className="head24">{item.maxValue}</div>
                      </div>
                    </Col>
                    <Col xs={0} sm={0} md={1} lg={1} xl={1}>
                      <Divider type="vertical" style={{ height: "100%" }} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                      <div className="text-center">
                        <div className="mt025 grey f12 ml025">Avg</div>
                        <div className="head24">{item.maxValue}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    gutter={[16, 16]}
                    justify="space-between"
                    className="pd05"
                  >
                    <Col span={12}>
                      <div className="vehicle-card text-center">
                        <span className="f12 grey mr05">Below Range</span>
                        <span className="head18 mr05">{item.minVehicles}%</span>
                        <a href="#123" onClick={showModal}>
                          Vehicles
                        </a>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="vehicle-card text-center">
                        <span className="f12 grey mr05">Above Range</span>
                        <span className="head18 mr05">{item.maxVehicles}%</span>
                        <a href="#123" onClick={showModal}>
                          Vehicles
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

export default Stack;
