import { Col, Modal, Row } from "antd";
import { useState } from "react";
import "./style.css";
import DaysPicker from "../Common/DaysPicker";
import Overview from "./Overview";
import Temperature from "./Temperature";
import Stack from "./Stack";
import MotorPanels from "./MotorPanels";
import BatteryPanels from "./BatteryPanels";

const Home = () => {
  const [topRunningModalOpen, setTopRunningModalOpen] = useState(false);
  const [temperatureModalOpen, setTemperatureModalOpen] = useState(false);
  const [sensorModalOpen, setSensorModalOpen] = useState(false);
  const [motorDetailModalOpen, setMotorDetailModalOpen] = useState(false);
  const [batteryDetailModalOpen, setBatteryDetailModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const showTopRunningModal = () => {
    setTopRunningModalOpen(true);
  };

  const showTemperatureModal = () => {
    setTemperatureModalOpen(true);
  };

  const showSensorModal = () => {
    setSensorModalOpen(true);
  };

  const showMotorDetailModal = (item) => {
    setSelectedItem(item);
    setMotorDetailModalOpen(true);
    setTemperatureModalOpen(false);
  };

  const showBatteryDetailModal = (item) => {
    setSelectedItem(item);
    setBatteryDetailModalOpen(true);
    setSensorModalOpen(false);
  };

  return (
    <Row>
      <Col span={24}>
        <Modal
          title="Top Running Vehicles"
          open={topRunningModalOpen}
          onCancel={() => setTopRunningModalOpen(false)}
          footer={[]}
        >
          <Row>
            <Col span={24} className="mt1">
              <ul className="most-active-ul">
                {[
                  {
                    slno: 1,
                    imei: "861100065560043",
                    distance: 40,
                  },
                  {
                    slno: 2,
                    imei: "868960069671127",
                    distance: 60,
                  },
                  {
                    slno: 3,
                    imei: "861100065560043",
                    distance: 140,
                  },
                  {
                    slno: 4,
                    imei: "868960069671127",
                    distance: 1190,
                  },
                  {
                    slno: 5,
                    imei: "861100065560043",
                    distance: 40,
                  },
                  {
                    slno: 6,
                    imei: "861100065560043",
                    distance: 40,
                  },
                  {
                    slno: 7,
                    imei: "868960069671127",
                    distance: 60,
                  },
                  {
                    slno: 8,
                    imei: "861100065560043",
                    distance: 140,
                  },
                  {
                    slno: 9,
                    imei: "868960069671127",
                    distance: 1190,
                  },
                  {
                    slno: 10,
                    imei: "861100065560043",
                    distance: 40,
                  },
                ].map((item) => {
                  return (
                    <li className="most-active-list">
                      <Row gutter={[16, 16]}>
                        <Col span={3}>{item.slno}</Col>
                        <Col span={17}>{item.imei}</Col>
                        <Col span={4}>{item.distance} km</Col>
                      </Row>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Modal>

        <Modal
          title={selectedItem?.imei}
          open={motorDetailModalOpen}
          onCancel={() => setMotorDetailModalOpen(false)}
          footer={[]}
          width={1200}
        >
          <Row>
            <Col span={24}>
              <MotorPanels imei={selectedItem?.imei} />
            </Col>
          </Row>
        </Modal>

        <Modal
          title={selectedItem?.imei}
          open={batteryDetailModalOpen}
          onCancel={() => setBatteryDetailModalOpen(false)}
          footer={[]}
          width={1200}
        >
          <Row>
            <Col span={24}>
              <BatteryPanels imei={selectedItem?.imei} />
            </Col>
          </Row>
        </Modal>

        <Modal
          title="Temperature Data"
          open={temperatureModalOpen}
          onCancel={() => setTemperatureModalOpen(false)}
          footer={[]}
        >
          <Row>
            <Col span={24} className="mt1">
              <ul className="most-active-ul">
                {[
                  {
                    slno: 1,
                    imei: "861100065560043",
                    distance: 40,
                  },
                  {
                    slno: 2,
                    imei: "868960069671127",
                    distance: 60,
                  },
                  {
                    slno: 3,
                    imei: "861100065560043",
                    distance: 140,
                  },
                  {
                    slno: 4,
                    imei: "868960069671127",
                    distance: 1190,
                  },
                  {
                    slno: 5,
                    imei: "861100065560043",
                    distance: 40,
                  },
                  {
                    slno: 6,
                    imei: "861100065560043",
                    distance: 40,
                  },
                  {
                    slno: 7,
                    imei: "868960069671127",
                    distance: 60,
                  },
                  {
                    slno: 8,
                    imei: "861100065560043",
                    distance: 140,
                  },
                  {
                    slno: 9,
                    imei: "868960069671127",
                    distance: 1190,
                  },
                  {
                    slno: 10,
                    imei: "861100065560043",
                    distance: 40,
                  },
                ].map((item, index) => {
                  return (
                    <li
                      className="most-active-list cursor-pointer"
                      onClick={() => showMotorDetailModal(item)}
                      key={index}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={3}>{item.slno}</Col>
                        <Col span={17}>{item.imei}</Col>
                        <Col span={4}>{item.distance}</Col>
                      </Row>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Modal>

        <Modal
          title="Sensor Data"
          open={sensorModalOpen}
          onCancel={() => setSensorModalOpen(false)}
          footer={[]}
        >
          <Row>
            <Col span={24} className="mt1">
              <ul className="most-active-ul">
                {[
                  {
                    slno: 1,
                    imei: "861100065560043",
                    distance: 40,
                  },
                  {
                    slno: 2,
                    imei: "868960069671127",
                    distance: 60,
                  },
                  {
                    slno: 3,
                    imei: "861100065560043",
                    distance: 140,
                  },
                  {
                    slno: 4,
                    imei: "868960069671127",
                    distance: 1190,
                  },
                  {
                    slno: 5,
                    imei: "861100065560043",
                    distance: 40,
                  },
                  {
                    slno: 6,
                    imei: "861100065560043",
                    distance: 40,
                  },
                  {
                    slno: 7,
                    imei: "868960069671127",
                    distance: 60,
                  },
                  {
                    slno: 8,
                    imei: "861100065560043",
                    distance: 140,
                  },
                  {
                    slno: 9,
                    imei: "868960069671127",
                    distance: 1190,
                  },
                  {
                    slno: 10,
                    imei: "861100065560043",
                    distance: 40,
                  },
                ].map((item, index) => {
                  return (
                    <li
                      className="most-active-list cursor-pointer"
                      onClick={() => showBatteryDetailModal(item)}
                      key={index}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={3}>{item.slno}</Col>
                        <Col span={17}>{item.imei}</Col>
                        <Col span={4}>{item.distance}</Col>
                      </Row>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Modal>

        <Overview showModal={showTopRunningModal} />

        <div className="widget mt1">
          <Row className="flex-center">
            <Col span={6} className="head18">
              Product Health Dashboard
            </Col>
            <Col span={18}>
              <div className="float-right">
                <DaysPicker />
              </div>
            </Col>
          </Row>

          <Temperature showModal={showTemperatureModal} />
          <Stack showModal={showSensorModal} />
        </div>
      </Col>
    </Row>
  );
};

export default Home;
