import { Col, Row } from "antd";
import { Sparklines, SparklinesLine } from "react-sparklines";

const Temperature = ({ showModal }) => {
  return (
    <Row gutter={[16, 16]} className="mt2">
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <div className="card">
          <Row>
            <Col span={24} className="text-center mt1">
              <div className="flex-center">
                <span className="normal-head">DC Current</span>
                <span className="mt025 f12 ml025">(A)</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="space-around" className="pd1">
            <Col span={10} className="grey-box">
              <div className="text-center">
                <div className="f12 grey">Below Range</div>
                <div className="head24 mt05">50%</div>
                <div>
                  <span className="toggle-btn" onClick={showModal}>
                    Vehicles
                  </span>
                </div>
              </div>
            </Col>
            <Col span={10} className="grey-box">
              <div className="text-center">
                <div className="f12 grey">Above Range</div>
                <div className="head24 mt05">50%</div>
                <div>
                  <span className="toggle-btn" onClick={showModal}>
                    Vehicles
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="flex-center">
              <Sparklines
                data={[
                  20, 42, 11, 25, 10, 35, 20, 18, 15, 35, 10, 5, 20, 48, 15, 5,
                  10, 35, 20, 8, 15,
                ]}
                limit={20}
                width={100}
                height={20}
                margin={5}
              >
                <SparklinesLine color="#0000b2" />
              </Sparklines>
            </Col>
          </Row>

          <Row className="grey-footer mt05 text-center">
            <Col span={8}>
              <div className="f12 grey">Min</div>
              <div className="head18">4567</div>
            </Col>
            <Col span={8}>
              <div className="f12 grey">Max</div>
              <div className="head18">4567</div>
            </Col>
            <Col span={8}>
              <div className="f12 grey">Avg</div>
              <div className="head18">4567</div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <div className="card">
          <Row>
            <Col span={24} className="text-center mt1">
              <div className="flex-center">
                <span className="normal-head">DC Voltage</span>
                <span className="mt025 f12 ml025">(V)</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="space-around" className="pd1">
            <Col span={10} className="grey-box">
              <div className="text-center">
                <div className="f12 grey">Below Range</div>
                <div className="head24 mt05">50%</div>
                <div>
                  <span className="toggle-btn" onClick={showModal}>
                    Vehicles
                  </span>
                </div>
              </div>
            </Col>
            <Col span={10} className="grey-box">
              <div className="text-center">
                <div className="f12 grey">Above Range</div>
                <div className="head24 mt05">50%</div>
                <div>
                  <span className="toggle-btn" onClick={showModal}>
                    Vehicles
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="flex-center">
              <Sparklines
                data={[
                  20, 42, 11, 25, 10, 35, 20, 18, 15, 35, 10, 5, 20, 48, 15, 5,
                  10, 35, 20, 8, 15,
                ]}
                limit={20}
                width={100}
                height={20}
                margin={5}
              >
                <SparklinesLine color="#0000b2" />
              </Sparklines>
            </Col>
          </Row>

          <Row className="grey-footer mt05 text-center">
            <Col span={8}>
              <div className="f12 grey">Min</div>
              <div className="head18">4567</div>
            </Col>
            <Col span={8}>
              <div className="f12 grey">Max</div>
              <div className="head18">4567</div>
            </Col>
            <Col span={8}>
              <div className="f12 grey">Avg</div>
              <div className="head18">4567</div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <div className="card">
          <Row>
            <Col span={24} className="text-center mt1">
              <div className="flex-center">
                <span className="normal-head">MCS Temperature</span>
                <span className="mt025 f12 ml025">(C)</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="space-around" className="pd1">
            <Col span={10} className="grey-box">
              <div className="text-center">
                <div className="f12 grey">Below Range</div>
                <div className="head24 mt05">50%</div>
                <div>
                  <span className="toggle-btn" onClick={showModal}>
                    Vehicles
                  </span>
                </div>
              </div>
            </Col>
            <Col span={10} className="grey-box">
              <div className="text-center">
                <div className="f12 grey">Above Range</div>
                <div className="head24 mt05">50%</div>
                <div>
                  <span className="toggle-btn" onClick={showModal}>
                    Vehicles
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="flex-center">
              <Sparklines
                data={[
                  20, 42, 11, 25, 10, 35, 20, 18, 15, 35, 10, 5, 20, 48, 15, 5,
                  10, 35, 20, 8, 15,
                ]}
                limit={20}
                width={100}
                height={20}
                margin={5}
              >
                <SparklinesLine color="#0000b2" />
              </Sparklines>
            </Col>
          </Row>

          <Row className="grey-footer mt05 text-center">
            <Col span={8}>
              <div className="f12 grey">Min</div>
              <div className="head18">4567</div>
            </Col>
            <Col span={8}>
              <div className="f12 grey">Max</div>
              <div className="head18">4567</div>
            </Col>
            <Col span={8}>
              <div className="f12 grey">Avg</div>
              <div className="head18">4567</div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={6} xl={6}>
        <div className="card">
          <Row>
            <Col span={24} className="text-center mt1">
              <div className="flex-center">
                <span className="normal-head">Motor Temperature</span>
                <span className="mt025 f12 ml025">(C)</span>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="space-around" className="pd1">
            <Col span={10} className="grey-box">
              <div className="text-center">
                <div className="f12 grey">Below Range</div>
                <div className="head24 mt05">50%</div>
                <div>
                  <span className="toggle-btn" onClick={showModal}>
                    Vehicles
                  </span>
                </div>
              </div>
            </Col>
            <Col span={10} className="grey-box">
              <div className="text-center">
                <div className="f12 grey">Above Range</div>
                <div className="head24 mt05">50%</div>
                <div>
                  <span className="toggle-btn" onClick={showModal}>
                    Vehicles
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="flex-center">
              <Sparklines
                data={[
                  20, 42, 11, 25, 10, 35, 20, 18, 15, 35, 10, 5, 20, 48, 15, 5,
                  10, 35, 20, 8, 15,
                ]}
                limit={20}
                width={100}
                height={20}
                margin={5}
              >
                <SparklinesLine color="#0000b2" />
              </Sparklines>
            </Col>
          </Row>

          <Row className="grey-footer mt05 text-center">
            <Col span={8}>
              <div className="f12 grey">Min</div>
              <div className="head18">4567</div>
            </Col>
            <Col span={8}>
              <div className="f12 grey">Max</div>
              <div className="head18">4567</div>
            </Col>
            <Col span={8}>
              <div className="f12 grey">Avg</div>
              <div className="head18">4567</div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Temperature;
