import { Col, Empty, Row, Table, Card, Divider } from "antd";
import { useSelector } from "react-redux";
import { UNITS } from "../../../../Common/Constants";
import {
  formatDateAndTime,
  getGrafanaInterval,
} from "../../../../Common/Utils";
import BATTERY from "../../../../../assets/images/icons/battery.svg";
import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import AddToCompare from "../addToCompare";
import PanelRow from "../panelRow";
import { grafanaBaseUrl } from "../../../../Common/endpoints";

const BatteryPerformance = ({ compareValue, updateCompareValue }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const vehicleDetails = useSelector((state) => state.eol.vehicleDetails);
  const tripSummary = useSelector((state) => state.testRide.tripSummary);
  const [view, setView] = useState(false);

  const onViewChange = () => {
    setView(!view);
  };

  const batteryVoltageLineChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=31`;
  const batteryVoltageBarChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=32`;
  const batteryVoltageBoxChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=33`;
  const batteryCurrentLineChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=34`;
  const batteryCurrentBarChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=35`;
  const batteryCurrentBoxChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=36`;

  const batterySOCLineChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=49`;
  const batterySOCBarChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=50`;
  const batterySOCBoxChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=51`;
  const batterySOHLineChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=52`;
  const batterySOHBarChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=53`;
  const batterySOHBoxChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=54`;

  const batteryMaxTempLineChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=55`;
  const batteryMaxTempBarChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=56`;
  const batteryMaxTempBoxChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=57`;
  const batteryMinTempLineChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=58`;
  const batteryMinTempBarChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=61`;
  const batteryMinTempBoxChart = `${grafanaBaseUrl}/d-solo/f6d62853-ec86-47d7-a058-850dd6167996/test-drive-summary?orgId=${
    userDetails?.currentOrganisation?.id
  }&var-imei=${vehicleDetails?.imeiNumber}&var-testid=${
    tripSummary?.rideForm?.testId
  }&var-interval=${getGrafanaInterval(
    tripSummary?.rideForm?.startTime,
    tripSummary?.rideForm?.stopTime
  )}&from=${tripSummary?.rideForm?.startTime}&to=${
    tripSummary?.rideForm?.stopTime
  }&panelId=60`;

  const columns = [
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => formatDateAndTime(startTime),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (endTime) => formatDateAndTime(endTime),
    },
    {
      title: "Start Voltage",
      dataIndex: "startVoltage",
      key: "startVoltage",
    },
    {
      title: "End Voltage",
      dataIndex: "endVoltage",
      key: "endVoltage",
    },
    {
      title: "Starting Charge",
      dataIndex: "startSoc",
      key: "startSoc",
    },
    {
      title: "Ending Charge",
      dataIndex: "endSoc",
      key: "endSoc",
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
    },
  ];

  return (
    <Card
      className="ride-statistic-analytics-card"
      title={
        <Row justify={"space-between"} onClick={onViewChange}>
          <Col className="flex-center">
            <img src={BATTERY} alt="Battery" className="icon-size mr05" />{" "}
            Battery Performance
          </Col>
          <Col className="font-weight-normal">
            View More
            {view ? (
              <UpOutlined className="ml05" />
            ) : (
              <DownOutlined className="ml05" />
            )}
          </Col>
        </Row>
      }
    >
      <Row gutter={[16, 16]} className="mt05" onClick={onViewChange}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          Total Distance: {tripSummary?.totalDistance} {UNITS.KM}
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          Total Discharge: {tripSummary?.totalDischarge}
          {UNITS.PERCENT}
        </Col>
      </Row>
      {view ? (
        <>
          <Row className="mt1">
            <Col span={24}>
              {tripSummary?.batteryPerformance?.length > 0 ? (
                <Table
                  columns={columns}
                  dataSource={tripSummary?.batteryPerformance}
                  rowKey={(record) => record.startTime}
                  pagination={false}
                  scroll={{ x: "100%" }}
                />
              ) : (
                <Empty />
              )}
            </Col>
          </Row>

          <Row className="mt1 pd0">
            <Col span={24}>
              {vehicleDetails?.imeiNumber && (
                <PanelRow
                  title={"Battery Voltage"}
                  timeSeriesURL={batteryVoltageLineChart}
                  histogramURL={batteryVoltageBarChart}
                  boxPlotURL={batteryVoltageBoxChart}
                />
              )}
              <Row className="mt1 mb1">
                <Col className="add-compare-col">
                  <AddToCompare
                    value="batteryVoltage"
                    compareValue={compareValue}
                    updateCompareValue={updateCompareValue}
                  />
                </Col>
              </Row>
              <Row>
                <Divider
                  style={{
                    background: "#eee",
                    height: "4px",
                    margin: "12px 0",
                  }}
                />
              </Row>
              {vehicleDetails?.imeiNumber && (
                <PanelRow
                  title={"Battery Current"}
                  timeSeriesURL={batteryCurrentLineChart}
                  histogramURL={batteryCurrentBarChart}
                  boxPlotURL={batteryCurrentBoxChart}
                />
              )}
              <Row className="mt1 mb1">
                <Col className="add-compare-col">
                  <AddToCompare
                    value="batteryCurrent"
                    compareValue={compareValue}
                    updateCompareValue={updateCompareValue}
                  />
                </Col>
              </Row>
              <Row>
                <Divider
                  style={{
                    background: "#eee",
                    height: "4px",
                    margin: "12px 0",
                  }}
                />
              </Row>
              {vehicleDetails?.imeiNumber && (
                <PanelRow
                  title={"Battery SOC"}
                  timeSeriesURL={batterySOCLineChart}
                  histogramURL={batterySOCBarChart}
                  boxPlotURL={batterySOCBoxChart}
                />
              )}
              <Row className="mt1 mb1">
                <Col className="add-compare-col">
                  <AddToCompare
                    value="batterySOC"
                    compareValue={compareValue}
                    updateCompareValue={updateCompareValue}
                  />
                </Col>
              </Row>
              <Row>
                <Divider
                  style={{
                    background: "#eee",
                    height: "4px",
                    margin: "12px 0",
                  }}
                />
              </Row>
              {vehicleDetails?.imeiNumber && (
                <PanelRow
                  title={"Battery SOH"}
                  timeSeriesURL={batterySOHLineChart}
                  histogramURL={batterySOHBarChart}
                  boxPlotURL={batterySOHBoxChart}
                />
              )}
              <Row className="mt1 mb1">
                <Col className="add-compare-col">
                  <AddToCompare
                    value="batterySOH"
                    compareValue={compareValue}
                    updateCompareValue={updateCompareValue}
                  />
                </Col>
              </Row>
              <Row>
                <Divider
                  style={{
                    background: "#eee",
                    height: "4px",
                    margin: "12px 0",
                  }}
                />
              </Row>
              {vehicleDetails?.imeiNumber && (
                <PanelRow
                  title={"Battery Max Temperature"}
                  timeSeriesURL={batteryMaxTempLineChart}
                  histogramURL={batteryMaxTempBarChart}
                  boxPlotURL={batteryMaxTempBoxChart}
                />
              )}
              <Row className="mt1 mb1">
                <Col className="add-compare-col">
                  <AddToCompare
                    value="batteryMaxTemp"
                    compareValue={compareValue}
                    updateCompareValue={updateCompareValue}
                  />
                </Col>
              </Row>
              <Row>
                <Divider
                  style={{
                    background: "#eee",
                    height: "4px",
                    margin: "12px 0",
                  }}
                />
              </Row>
              {vehicleDetails?.imeiNumber && (
                <PanelRow
                  title={"Battery Min Temperature"}
                  timeSeriesURL={batteryMinTempLineChart}
                  histogramURL={batteryMinTempBarChart}
                  boxPlotURL={batteryMinTempBoxChart}
                />
              )}
              <Row className="mt1 mb1">
                <Col className="add-compare-col">
                  <AddToCompare
                    value="batteryMinTemp"
                    compareValue={compareValue}
                    updateCompareValue={updateCompareValue}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : null}
    </Card>
  );
};

export default BatteryPerformance;
