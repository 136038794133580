import { Route, Routes, Navigate } from "react-router-dom";
import AuthLayout from "./components/AuthLayout";
import Login from "./components/Login";
import { useSelector } from "react-redux";
import MotorStatus from "./components/Dashboard/MotorStatus";
import DriverBehaviour from "./components/Dashboard/DriverBehaviour";
import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Register from "./components/Register";
import RegistrationConfirm from "./components/RegistrationConfirm";
import Logout from "./components/Common/Logout";
import TestRides from "./components/Dashboard/TestRides";
import EolTests from "./components/Dashboard/EOL/Tests";
import EolStatistics from "./components/Dashboard/EOL/Statistics";
import { ROUTES } from "./components/Common/Constants";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import FleetStatus from "./components/Dashboard/FleetStatus";
import CumulativeSummary from "./components/Dashboard/CumulativeSummary";
import SlideShow from "./components/Dealers/SlideShow";
import Home from "./components/Home";

const Routing = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isDealerLayout = useSelector((state) => state.auth.dealerLayout);

  const renderIfLoggedIn = (component) => {
    return isLoggedIn ? (
      isDealerLayout ? (
        component
      ) : (
        <AuthLayout>{component}</AuthLayout>
      )
    ) : (
      <Navigate to="/login" replace={true} />
    );
  };

  const renderIfNotLoggedIn = (component) => {
    return !isLoggedIn ? component : <Navigate to="/" replace={true} />;
  };

  return (
    <Routes>
      {isDealerLayout ? (
        <Route
          path={ROUTES.DEFAULT}
          exact
          element={renderIfLoggedIn(<SlideShow />)}
        />
      ) : (
        <Route
          path={ROUTES.DEFAULT}
          exact
          element={renderIfLoggedIn(<Dashboard />)}
        />
      )}
      <Route
        path={ROUTES.DEALER_SLIDESHOW.link}
        exact
        element={renderIfLoggedIn(<SlideShow />)}
      />
      <Route
        path={ROUTES.HOME.link}
        exact
        element={renderIfLoggedIn(<Home />)}
      />
      <Route
        path={ROUTES.PROFILE.link}
        exact
        element={renderIfLoggedIn(<Profile />)}
      />
      <Route
        path={ROUTES.REGISTER.link}
        exact
        element={renderIfLoggedIn(<Register />)}
      />
      <Route
        path={ROUTES.TEST_RIDES.link}
        exact
        element={renderIfLoggedIn(<TestRides />)}
      />
      <Route
        path={ROUTES.LOGOUT.link}
        exact
        element={renderIfLoggedIn(<Logout />)}
      />

      <Route
        path={ROUTES.FORGOT_PASSWORD.link}
        element={renderIfNotLoggedIn(<ForgotPassword />)}
      />
      <Route
        path={ROUTES.CHANGE_PASSWORD.link}
        element={renderIfNotLoggedIn(<ChangePassword />)}
      />
      <Route
        path={ROUTES.LOGIN}
        exact
        element={renderIfNotLoggedIn(<Login />)}
      />
      <Route
        path={ROUTES.CONFIRM_REGISTRATION}
        element={renderIfNotLoggedIn(<RegistrationConfirm />)}
      />
      <Route
        path={ROUTES.MOTOR_STATUS.link}
        exact
        element={renderIfLoggedIn(<MotorStatus />)}
      />
      <Route
        path={ROUTES.DRIVER_BEHAVIOUR.link}
        exact
        element={renderIfLoggedIn(<DriverBehaviour />)}
      />
      <Route
        path={ROUTES.EOL_TESTS.link}
        exact
        element={renderIfLoggedIn(<EolTests />)}
      />
      <Route
        path={ROUTES.EOL_STATISTICS.link}
        exact
        element={renderIfLoggedIn(<EolStatistics />)}
      />
      <Route
        path={ROUTES.FLEET_STATUS.link}
        exact
        element={renderIfLoggedIn(<FleetStatus />)}
      />
      <Route
        path={ROUTES.TEST_RIDE_STATISTICS.link}
        exact
        element={renderIfLoggedIn(<CumulativeSummary />)}
      />
    </Routes>
  );
};

export default Routing;
