import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

const Display = ({
  selectedTime,
  handleButtonClick,
  nextDisable,
  startTime,
  endTime,
}) => (
  <div className="date flex-center">
    <Row gutter={[16, 16]}>
      <Col span={2} className="flex-center">
        <div onClick={() => handleButtonClick(true)} className="cursor-pointer">
          <LeftOutlined className="f16" />
        </div>
      </Col>
      <Col span={18} className="flex-center">
        <div className="f16">
          {selectedTime === "Hour"
            ? `${endTime.toLocaleTimeString([], {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })} - ${startTime.toLocaleTimeString([], {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              })}`
            : `${endTime.toLocaleString("default", {
                month: "short",
              })} ${endTime.getDate()} - ${startTime.toLocaleString("default", {
                month: "short",
              })} ${startTime.getDate()}`}
        </div>
      </Col>
      <Col span={2} className="flex-center">
        {nextDisable ? (
          <div>
            <RightOutlined className="f16 disabled" />
          </div>
        ) : (
          <div
            onClick={() => handleButtonClick(false)}
            className="cursor-pointer"
          >
            <RightOutlined className="f16" />
          </div>
        )}
      </Col>
    </Row>
  </div>
);
export default Display;
