export const motorFields = [
  {
    label: "X Axis Acceleration",
    value: "accel_x_axis",
  },
  {
    label: "Y Axis Acceleration",
    value: "accel_y_axis",
  },
  {
    label: "Z Axis Acceleration",
    value: "accel_z_axis",
  },
  {
    label: "AI Lean Angle",
    value: "ai_lean_angle",
  },
  {
    label: "AI System Voltage",
    value: "ai_system_voltage",
  },
  {
    label: "AI Temperature",
    value: "ai_temperature",
  },
  {
    label: "AI Vbuck",
    value: "ai_vbuck",
  },
  {
    label: "AI Voltage Input",
    value: "ai_voltage_input",
  },
  {
    label: "AI Vuser1",
    value: "ai_vusr1",
  },
  {
    label: "AI Vuser2",
    value: "ai_vusr2",
  },
  {
    label: "Gyro X Axis",
    value: "gyro_x_axis",
  },
  {
    label: "Gyro Y Axis",
    value: "gyro_y_axis",
  },
  {
    label: "Gyro Z Axis",
    value: "gyro_z_axis",
  },
  {
    label: "Motor DC Current",
    value: "motor_dc_current",
  },
  {
    label: "Motor DC Voltage",
    value: "motor_dc_voltage",
  },
  {
    label: "Motor MCS Temperature",
    value: "motor_mcs_temperature",
  },
  {
    label: "Motor Speed",
    value: "motor_speed",
  },
  {
    label: "Motor Temperature",
    value: "motor_temperature",
  },
  {
    label: "Motor Throttle",
    value: "motor_throttle",
  },
];
