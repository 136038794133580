import { Col, Row, Card } from "antd";
import { UNITS } from "../../../Common/Constants";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useState } from "react";
import { getGrafanaInterval } from "../../../Common/Utils";
import BATTERY from "../../../../assets/images/icons/battery.svg";
import Iframe from "../../../Common/Iframe";
import { grafanaBaseUrl } from "../../../Common/endpoints";

const BatteryPerformance = ({ tripSummary, summaryList }) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const summaryCount = summaryList?.length;
  const [view, setView] = useState(false);

  const onViewChange = () => {
    setView(!view);
  };

  const imeiURL =
    tripSummary?.imei?.length > 0
      ? "&var-imei=" + tripSummary.imei.join("&var-imei=")
      : "";
  const ridersURL =
    tripSummary?.riders?.length > 0
      ? "&var-riders=" + tripSummary.riders.join("&var-riders=")
      : "";
  const batteryURL =
    tripSummary?.battery?.length > 0
      ? "&var-battery=" + tripSummary.battery.join("&var-battery=")
      : "";

  const batteryHistogramChart = `${grafanaBaseUrl}/d-solo/bf83e667-6e25-4c6a-950c-e099f47757bf/test-drive-cumulative-left?orgId=${
    userDetails?.currentOrganisation?.id
  }${imeiURL}${ridersURL}${batteryURL}&var-interval=${getGrafanaInterval(
    tripSummary?.startTime,
    tripSummary?.stopTime
  )}&from=${tripSummary?.startTime}&to=${tripSummary?.stopTime}&panelId=15`;

  const batteryBoxChart = `${grafanaBaseUrl}/d-solo/bf83e667-6e25-4c6a-950c-e099f47757bf/test-drive-cumulative-left?orgId=${
    userDetails?.currentOrganisation?.id
  }${imeiURL}${ridersURL}${batteryURL}&var-interval=${getGrafanaInterval(
    tripSummary?.startTime,
    tripSummary?.stopTime
  )}&from=${tripSummary?.startTime}&to=${tripSummary?.stopTime}&panelId=16`;

  return (
    <Card
      onClick={onViewChange}
      className="cursor-pointer"
      title={
        <Row justify={"space-between"}>
          <Col className="align-center">
            <img src={BATTERY} alt="Battery" className="icon-size mr05" />{" "}
            Battery Performance
          </Col>
          <Col className="font-weight-normal">
            View More
            {view ? (
              <UpOutlined className="ml05" />
            ) : (
              <DownOutlined className="ml05" />
            )}
          </Col>
        </Row>
      }
    >
      <Row gutter={[16, 16]} className="mt05" onClick={onViewChange}>
        <Col
          xs={24}
          sm={12}
          md={summaryCount >= 2 ? 24 : 12}
          lg={12}
          xl={summaryCount >= 2 ? 12 : 10}
        >
          Total Distance: {tripSummary?.totalDistance} {UNITS.KM}
        </Col>
        <Col
          xs={24}
          sm={12}
          md={summaryCount >= 2 ? 24 : 12}
          lg={12}
          xl={summaryCount >= 2 ? 12 : 10}
        >
          Total Discharge: {tripSummary?.totalDischarge}
          {UNITS.PERCENT}
        </Col>
      </Row>
      {view ? (
        <Row gutter={16} className="mt1">
          <Col xs={24} lg={summaryCount >= 2 ? 24 : 12} xxl={12}>
            <Iframe
              title="Histogram - Cumulative Battery Voltage"
              src={batteryHistogramChart}
              width="100%"
              height="360"
            />
          </Col>
          <Col xs={24} lg={summaryCount >= 2 ? 24 : 12} xxl={12}>
            <Iframe
              title="Box Plot - Cumulative Battery Voltage"
              src={batteryBoxChart}
              width="100%"
              height="360"
            />
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};

export default BatteryPerformance;
